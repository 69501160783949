import React from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/global/breadcrumbs";
import Footer from "../components/global/footer";
import Navigation from "../components/global/navigation";
import SEO from "../components/global/seo";
import ComplaintsForm from "../components/local/complaints-form";

// CSS
import '../styles.css'

const Complaints = () => {
   return (
      <React.Fragment>
         <SEO
            title="Submit a complaint"
            slug="complaints"
            metaDescription="We're sorry to hear that you're not happy with your policy. Submit a complaint with us and we'll get right on it to resolve your issue."
            image=""
            titleTemplate="%s - Appliance Sure"
         />

         <Navigation/>

         <div className="content-hero-container">
            <div className="content-hero-content-container">
               <h1>Submit a complaint</h1>
               <p>We're sorry that you're not happy. We'd love to help you out and resolve your issue, please follow the instructions below to begin the complaints process.</p>
            </div>
         </div>

         <div className="standard-container">
            <div className="content-split-container narrow-width">
               <div className="content-container">
                  <Breadcrumbs
                     location="complaints"
                  />

                  <ComplaintsForm/>
               </div>
               <div className="side-bar-container">
                  <div className="side-bar-entries">
                  <span>
                     <input
                        type="checkbox"
                        defaultChecked={true}
                        id="related-articles"
                        className="related-articles-trigger invisible"
                     />

                     <label className="related-articles-label" htmlFor="related-articles">Our process</label>

                     <div className="related-articles-content complaints-process">
                        <h3>Step 1</h3>
                        <p>Fill in the form on this page following the instructions. You will receive email confirmation of our receipt of this.</p>

                        <h3>Step 2</h3>
                        <p>We'll work on resolving your issue, we may call you to further investigate.</p>

                        <h3>Step 3</h3>
                        <p>Within 56 days you'll receive our final decision on your complaint. If it takes us longer than 56 days or you're still not happy you have the right to take the complaint further. Read our <a className="underlined-link" href="/complaints-policy" target="_blank">complaints policy</a> for more details.</p>
                     </div>
                  </span>
                  </div>
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default Complaints