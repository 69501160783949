import React, { useState } from "react"
import Error from "../../../global/error"

const CustomerIdentificationScreen = ({
   setStage,
   complaintData,
   updateComplaintData
}) => {
   const handleFillInForm = (event) => {
      const name = event.target.name;
      let val = event.target.value;
      if(name === 'knowsServiceNumber') val = !event.target.checked

      if(val === 'true') {
         val = true
      } else if (val === 'false') {
         val = false
      }

      updateComplaintData({
         ...complaintData,
         [name]: val
      })
   }

   const goToNextPage = (event) => {
      event.preventDefault()

      setStage(2)
   }

   return (
      <React.Fragment>
         <p style={{marginBottom: 10}}>Complaints form - Step 1 / 5</p>
         <h2 style={{marginTop: 0}}>Are you a customer?</h2>
         
         <form onChange={handleFillInForm}>
            <div className="select-option-grid">
               <span>
                  <input
                     className="select-option-trigger invisible"
                     type="radio"
                     value="current_customer"
                     id="current_customer"
                     name="customerType"
                     checked={complaintData.customerType === 'current_customer'}
                  />

                  <label htmlFor="current_customer" className="select-option">Yes</label>
               </span>

               <span>
                  <input
                     className="select-option-trigger invisible"
                     type="radio"
                     value="ex_customer"
                     id="ex_customer"
                     name="customerType"
                     checked={complaintData.customerType === 'ex_customer'}
                  />

                  <label htmlFor="ex_customer" className="select-option">Previously</label>
               </span>

               <span>
                  <input
                     className="select-option-trigger invisible"
                     type="radio"
                     value="non_customer"
                     id="non_customer"
                     name="customerType"
                     checked={complaintData.customerType === 'non_customer'}
                  />

                  <label htmlFor="non_customer" className="select-option">No</label>
               </span>
            </div>

         {
            complaintData.customerType !== '' && complaintData.customerType !== 'non_customer' ? (
               <React.Fragment>
                  <br/>
                  <br/>

                  <label className="form-label">Is this complaint on behalf of someone else? *</label>

                  <select 
                     className="form-input" 
                     value={complaintData.onBehalfOfOther}
                     name="onBehalfOfOther"
                  >
                     <option value="" selected disabled>Select an option</option>
                     <option value={false}>It is my complaint</option>
                     <option value={true}>On behalf of someone else</option>
                  </select>

                  {
                     complaintData.knowsServiceNumber ? (
                        <React.Fragment>
                           <label className="form-label">Do you know your service number?</label>
                           <input
                              type="text"
                              className="form-input"
                              placeholder="Enter your service number"
                              name="serviceNumber"
                              value={complaintData.serviceNumber}
                           />
                        </React.Fragment>
                     ) : null
                  }
                  
                  <div className='checkbox-form-container'>
                     <input
                        type="checkbox"
                        className='checkbox-trigger invisible'
                        id="knowsServiceNumber"
                        name="knowsServiceNumber"
                        checked={!complaintData.knowsServiceNumber}
                     />
                     <label htmlFor='knowsServiceNumber' className="checkbox"/>

                     <span style={{textAlign: 'left'}}>
                        <label htmlFor='knowsServiceNumber'>I don't know my service number</label>
                     </span>
                  </div>
               </React.Fragment>
            ) : null
         }

         {
            complaintData.customerType === 'non_customer' ||
            (complaintData.onBehalfOfOther !== '' && (complaintData.serviceNumber !== '' || complaintData.knowsServiceNumber === false)) ? (
               <button style={{marginTop:60}} className="standard-button primary" onClick={goToNextPage}>Continue</button>
            ) : null
         }
         
         </form>
      </React.Fragment>
   )
   
}

export default CustomerIdentificationScreen