import React, { useState } from "react";
import Error from "../../../global/error";
import FormData from "form-data";
import axios from "axios";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";


const AboutComplaintScreen = ({
   setStage,
   complaintData,
   updateComplaintData
}) => {
   const [errors, setErrors] = useState({
      complaint: false,
      desiredResolution: false,
      fileSize: false
   })

   const UploadImageProgress = () => {
      const { promiseInProgress } = usePromiseTracker();

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Uploading...</h3>
         </div>
      )
   }

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateComplaintData({
         ...complaintData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const goToNextPage = (event) => {
      event.preventDefault();

      let errors_count = 0;
      let errors_object = {}

      const fields = ["complaint", "desiredResolution"]

      for (let i = 0; i < fields.length; i++) {
         const el = fields[i];
         
         if(complaintData[el] === '') {
            errors_count++
            errors_object[el] = true
         }
      }

      if(errors_count === 0) {
         setStage(4)
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })
      }
   }

   const uploadFileToZoho = async (path) => {
      return new Promise((resolve, reject) => {
         setTimeout(async () => {
            const zoho_upload = await axios({
               method: 'post',
               url: 'https://api.appliancesure.com/zero/gen/files/upload/zoho',
               data: {
                  url: path
               },
               headers: {
                  'Content-Type':'application/json',
                  'Access-Control-Allow-Origin': '*'
               }
            })
      
            try {
               resolve(zoho_upload.data)
            }
      
            catch (err) {
               reject(err)
            }
         }, 1000)
      })
      
   }

   const createTempFile = async (file) => {
      return trackPromise(
         new Promise( async (resolve, reject) => {
            const form = new FormData()
            form.append("file", file)
      
            const path = await axios({
               method: 'post',
               url: 'https://api.appliancesure.com/zero/gen/files/upload',
               data: form,
               headers: {
                  'Content-Type':'multipart/form-data'
               }
            })
      
            try {
               const path_url = await path.data.response
      
               const zoho_path = await uploadFileToZoho(path_url)
      
               resolve(zoho_path.id)
            }
      
            catch (err) {
               reject(err)
            }
         })
      )
   }

   const handleUploadFiles = async (event) => {
      const new_file = event.target.files[0];

      setErrors({
         ...errors,
         fileSize: false
      })

      if(new_file.size <= 200000000) {
         let files_array = complaintData.documents.documents;
         files_array.push(new_file)

         let id_array = complaintData.documents.ids;
         const file_id = await createTempFile(new_file)
         id_array.push(file_id)
   
         updateComplaintData({
            ...complaintData,
            documents: {
               ...complaintData.documents,
               totalCount: complaintData.documents.totalCount + 1,
               documents: files_array,
               ids: id_array
            }
         })
      } else {
         setErrors({
            ...errors,
            fileSize: true
         })
      } 
   }

   const handleRemoveFile = (event, i) => {
      event.preventDefault();

      let new_array = complaintData.documents.documents
      new_array.splice(i, 1)

      updateComplaintData({
         ...complaintData,
         documents: { 
            ...complaintData.documents,
            documents: new_array,
            totalCount: complaintData.documents.totalCount - 1
         }
      })
   }

   const createFilesList = () => {
      let jsx = []

      for (let i = 0; i < complaintData.documents.documents.length; i++) {
         const el = complaintData.documents.documents[i];

         jsx.push(
            <button className="access-selected-item" onClick={(e) => handleRemoveFile(e, i)}>{el.name}</button>
         )
         
      }

      return [
         <div className="access-selected-container">
            {jsx}
         </div>
      ]
   }

   return (
      <React.Fragment>
         <UploadImageProgress/>
         <button className="plain-text-button" onClick={() => setStage(2)}><i class="fa-solid fa-arrow-left"></i> Go back</button>

         <br/>
         <br/>

         <p style={{marginBottom: 10}}>Complaints form - Step 3 / 5</p>
         <h2 style={{marginTop: 0}}>About your complaint</h2>
         
         <form onChange={handleFillInForm}>
            <label className="form-label">Let us know what happened *</label>

            <textarea
               value={complaintData.complaint}
               name="complaint"
               placeholder="Please write your complaint here"
               style={{marginTop: 10}}
               maxLength="5000"
            />

            <p className="word-count">{complaintData.complaint.length} / 5,000 characters</p>

            {
               errors.complaint ? (
                  <Error message="Please write your complaint" />
               ) : null
            }

            <label className="form-label">What is your desired resolution? *</label>

            <textarea
               value={complaintData.desiredResolution}
               name="desiredResolution"
               placeholder="Please write your desired resolution here"
               style={{marginTop: 10}}
               maxLength="1000"
            />

            <p className="word-count">{complaintData.desiredResolution.length} / 1,000 characters</p>

            {
               errors.desiredResolution ? (
                  <Error message="Please let us know how you'd like this to be resolved" />
               ) : null
            }
         </form>
         
         <form style={{marginBottom: 60}}>
            <label className="form-label">Supporting documentation & evidence</label>
            <p>Upload up to 3 documents (max 20mb per file) that you believe will support your complaint or you would like us to take into consideration.</p>

            {createFilesList()}
            
            {
               complaintData.documents.totalCount < 3 ? (
                  <label htmlFor="upload" className={`standard-button secondary upload ${complaintData.documents.totalCount >= 3 ? 'disabled' : ''}`}>Upload file</label>               
               ) : null
            }
            
            <input
               type="file"
               className="invisible"
               id="upload"
               disabled={complaintData.documents.totalCount >= 3}
               onChange={handleUploadFiles}
            />

            <p>{complaintData.documents.totalCount} / 3 available uploads</p>

            {
               errors.fileSize ? (
                  <React.Fragment>
                     <div style={{height: 20}}/>
                     <Error message="The file that you are trying to upload is larger than the 20mb limit, please select another."/>
                  </React.Fragment>
               ) : null
            }
         </form>
         

         <button className="standard-button primary" onClick={goToNextPage}>Continue</button>
         
      </React.Fragment>
   )
}

export default AboutComplaintScreen