import React, { useState } from "react";
import Error from "../../../global/error";

const NatureOfComplaintScreen = ({
   setStage,
   complaintData,
   updateComplaintData
}) => {
   const [errors, setErrors] = useState({
      natureComplaint: false,
      ifOtherNature: false
   })

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateComplaintData({
         ...complaintData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const goToNextPage = (event) => {
      event.preventDefault();

      if(complaintData.natureComplaint === 'Other' && complaintData.ifOtherNature === '') {
         setErrors({
            ...errors,
            ifOtherNature: true
         })
      } else if (complaintData.natureComplaint === '') {
         setErrors({
            ...errors,
            natureComplaint: true
         })
      } else {
         setStage(3)
      }
   }
   
   return (
      <React.Fragment>
         <button className="plain-text-button" onClick={() => setStage(1)}><i class="fa-solid fa-arrow-left"></i> Go back</button>

         <br/>
         <br/>

         <p style={{marginBottom: 10}}>Complaints form - Step 2 / 5</p>
         <h2 style={{marginTop: 0}}>Nature of your complaint</h2>
         
         <form onChange={handleFillInForm}>
            <label className="form-label">Which element of our services would you like to complain about? *</label>

            <select 
               className="form-input"
               value={complaintData.natureComplaint}
               name="natureComplaint"
            >
               <option value="" selected disabled>Choose an option</option>
               <option value="Policy">My policy</option>
               <option value="Charges / fees">Charges or fees</option>
               <option value="Engineer visit">Engineer visit</option>
               <option value="Our staff">Our staff</option>
               <option value="Sales process">Sales process</option>
               <option value="Other">Other</option>
            </select>

            {
               errors.natureComplaint ? (
                  <React.Fragment>
                     <Error message="Please tell us the nature of your complaint" />
                  </React.Fragment>
               ) : null
            }

            {
               complaintData.natureComplaint === 'Other' ? (
                  <React.Fragment>
                     <textarea
                        value={complaintData.ifOtherNature}
                        name="ifOtherNature"
                        placeholder="Tell use the category of your complaint (You'll get to write your full complaint later)"
                     />

                     {
                        errors.ifOtherNature ? (
                           <React.Fragment>
                              <div style={{height: 30}}/>
                              <Error message="Please tell us the nature of your complaint" />
                           </React.Fragment>
                        ) : null
                     }

                     <br/>
                     <br/>
                  </React.Fragment>
               ) : null
            }

            <button className="standard-button primary" onClick={goToNextPage}>Continue</button>
         </form>
      </React.Fragment>
   )
}

export default NatureOfComplaintScreen