import React, { useState, useEffect } from "react";
import AboutComplaintScreen from "./screens/about-complaint";
import AboutYouScreen from "./screens/about-you";
import CustomerIdentificationScreen from "./screens/customer-identification";
import NatureOfComplaintScreen from "./screens/nature-of-complaints";
import SuccessScreen from "./screens/success";

const ComplaintsForm = () => {
   useEffect(() => {
      window.scrollTo({ top: 0 })
   }, [])

   // States
   const [stage, setStage] = useState(1)
   const [complaintData, updateComplaintData] = useState({
      customerType: '',
      onBehalfOfOther: '',
      knowsServiceNumber: true,
      serviceNumber: '',
      natureComplaint: '',
      ifOtherNature: '',
      complaint: '',
      desiredResolution: '',
      documents: {
         totalCount: 0,
         documents: [],
         ids: []
      },
      digitalFingerprint: {
         ipAddress: '',
         location: '',
         operatingSystem: '',
         browser: '',
         timestamp: '',
         reCaptchaFingerprint: ''
      },
      personal: {
         firstName: '',
         lastName: '',
         emailAddress: '',
         phoneNumber: '',
         postcode: ''
      }
   })

   switch (stage) {
      case 1:
         return <CustomerIdentificationScreen
                  setStage={setStage}
                  complaintData={complaintData}
                  updateComplaintData={updateComplaintData}
               />

      case 2:
         return <NatureOfComplaintScreen
                  setStage={setStage}
                  complaintData={complaintData}
                  updateComplaintData={updateComplaintData}
               />
      
      case 3:
         return <AboutComplaintScreen
                  setStage={setStage}
                  complaintData={complaintData}
                  updateComplaintData={updateComplaintData}
               />

      case 4:
         return <AboutYouScreen
                  setStage={setStage}
                  complaintData={complaintData}
                  updateComplaintData={updateComplaintData}
               />

      case 5:
         return <SuccessScreen
                  complaintData={complaintData}
               />
   }
}

export default ComplaintsForm