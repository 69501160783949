import React, { useEffect } from 'react'

const SuccessScreen = ({ complaintData }) => {
   useEffect(() => {
      window.scrollTo({ top: 450, behavior: 'smooth' })
   }, [])

   return (
      <React.Fragment>
         <p style={{marginBottom: 10}}>Cancellation form - Step 5 / 5</p>
         <h2 style={{marginTop: 0}}>Your complaint has been submitted successfully <i class="fa-solid fa-circle-check"></i></h2>

         <p>For now there is nothing more to do. You will receive an email shortly confirming that we have received your complaint.</p>

         <p>If we require any more information from you we will contact you on the phone number or email address that we have registered on your account. Once we have a decision on your complaint, we will send this to you in writing for your records.</p>

         <p>We're sorry that you are unhappy and we really hope that we're able to come to a resolution for you.</p>
         
         <br/>

         <a className="standard-button primary" href='/'>Go to homepage</a>
      </React.Fragment>
   )
}

export default SuccessScreen