import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Error from "../../../global/error";
import axios from "axios";
import { detect } from 'detect-browser'
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";

const AboutYouScreen = ({
   setStage,
   complaintData,
   updateComplaintData
}) => {
   const [errors, setErrors] = useState({
      firstName: false,
      lastName: false,
      emailAddress: false,
      phoneNumber: false,
      postcode: false,
      recaptcha: false,
      submission: false
   })

   const [recaptchaVerified, setRecaptchVerified] = useState(true)

   const SubmissionProgress = () => {
      const { promiseInProgress } = usePromiseTracker();

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Submitting complaint...</h3>
         </div>
      )
   }

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateComplaintData({
         ...complaintData,
         personal: {
            ...complaintData.personal,
            [name]: val
         }
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const createDigitalFingerprint = async (fingerprint) => {
      const ip_lookup = await axios.get("https://geolocation-db.com/json/")
      const browser = detect();

      updateComplaintData({
         ...complaintData,
         digitalFingerprint: {
            ipAddress: ip_lookup.data.IPv4,
            location: `${ip_lookup.data.state}, ${ip_lookup.data.country_name}`,
            operatingSystem: browser.os,
            browser: browser.name,
            timestamp: new Date(),
            reCaptchaFingerprint: fingerprint
         }
      })
   }

   const handleRobotVerification = (e) => {
      if(e === null) {
         setRecaptchVerified(false)
      } else {
         setRecaptchVerified(true)
         createDigitalFingerprint(e)
         setErrors({
            ...errors,
            recaptcha: false
         })
      }
   }

   const handleDataValidation = () => {
      let errors = 0;
      let errors_object = {}

      if(complaintData.personal.firstName === '') {
         errors++
         errors_object.firstName = true
      }

      if(complaintData.personal.lastName === '') {
         errors++
         errors_object.lastName = true
      }

      if(complaintData.personal.emailAddress.length < 6 || complaintData.personal.emailAddress.includes("@") === false || complaintData.personal.emailAddress.includes(".") === false) {
         errors++
         errors_object.emailAddress = true
      }

      if(complaintData.personal.phoneNumber.length < 7) {
         errors++
         errors_object.phoneNumber = true
      }

      if(complaintData.customerType !== 'non_customer' && (complaintData.personal.postcode.length < 5 || complaintData.personal.postcode.length > 8)) {
         errors++
         errors_object.postcode = true
      }

      if(recaptchaVerified === false) {
         errors++
         errors_object.recaptcha = true
      }

      if(errors === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         window.scrollTo({top: 450, behavior: 'smooth'})
         
         return false
      }
   }

   const submitComplaint = async (event) => {
      event.preventDefault();

      if(handleDataValidation() === true) {
         await trackPromise(
            axios({
               method: 'post',
               url: 'https://api.appliancesure.com/one/customers/complaints/submitComplaint',
               params: {
                  source: 'Online form'
               },
               data: complaintData
            })
            .then((value) => {
               if(value.data.success === true) {
                  setStage(5)
               } else {
                  setErrors({
                     ...errors,
                     submission: true
                  })
               }
            })
            .catch((err) => {
               setErrors({
                  ...errors,
                  submission: false
               })
               console.log(err)
            })
         )
      }
   }

   return (
      <React.Fragment>
         <SubmissionProgress/>
         <button className="plain-text-button" onClick={() => setStage(3)}><i class="fa-solid fa-arrow-left"></i> Go back</button>

         <br/>
         <br/>

         <p style={{marginBottom: 10}}>Complaints form - Step 4 / 5</p>
         <h2 style={{marginTop: 0}}>About you</h2>

         <p>In order to {complaintData.customerType !== 'non_customer' ? 'identify your policy' : ''} and contact you with updates on your complaint, we'll need you to let us know a bit about yourself.</p>
         
         <form onChange={handleFillInForm} style={{marginTop: 40}}>
         <div className="form-2col">
               <span>
                  <label className="form-label">First name</label>
                  <input
                     className="form-input"
                     id="firstName"
                     name="firstName"
                     value={complaintData.personal.firstName}
                     placeholder="Please enter your first name"
                  />


                  {
                     errors.firstName ? (
                        <Error message="Please enter your first name "/>
                     ) : null
                  }
               </span>
               
               <span>
                  <label className="form-label">Last name</label>
                  <input
                     className="form-input"
                     id="lastName"
                     name="lastName"
                     value={complaintData.personal.lastName}
                     placeholder="Please enter your last name"
                  />

                  {
                     errors.lastName ? (
                        <Error message="Please enter your last name "/>
                     ) : null
                  }
               </span>
            </div>

            <label className="form-label">Email address</label>
            <input
               className="form-input"
               id="emailAddress"
               name="emailAddress"
               value={complaintData.personal.emailAddress}
               placeholder="Please enter your email address"
            />

            {
               errors.emailAddress ? (
                  <Error message="Enter a valid email address"/>
               ) : null
            }

            <label className="form-label">Phone number</label>
            <input
               className="form-input"
               id="phoneNumber"
               name="phoneNumber"
               value={complaintData.personal.phoneNumber}
               placeholder="Please enter your phone number"
            />

            {
               errors.phoneNumber ? (
                  <Error message="Enter a valid telephone number"/>
               ) : null
            }

            {
               complaintData.customerType !== 'non_customer' ? (
                  <div className="form-2col">
                     <span>
                        <label className="form-label">Postcode</label>
                        <input
                           className="form-input"
                           id="postcode"
                           name="postcode"
                           value={complaintData.personal.postcode}
                           placeholder="Please enter your postcode"
                        />

                        {
                           errors.postcode ? (
                              <Error message="Enter a valid UK postcode"/>
                           ) : null
                        }
                     </span>
                  </div>
               ) : null
            }
         </form>

         <ReCAPTCHA
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            onChange={(e) => handleRobotVerification(e)}
         />

         {
            errors.recaptcha ? (
               <React.Fragment>
                  <div style={{height: 30}}/>
                  <Error message="Please verify you are a human"/>
               </React.Fragment>
            ) : null
         }

         <br/>

         <button className="standard-button primary" onClick={submitComplaint}>Submit your complaint</button>
      </React.Fragment>
   )
}

export default AboutYouScreen